import React, { useState, useEffect, useRef } from "react"

// Component imports
import Text from "../Text"

// Static assets
import { ReactComponent as Plus } from "../../images/icons/plus.svg"
import { ReactComponent as Minus } from "../../images/icons/minus.svg"

interface Props {
  question: string
  answer: string
}

const FAQ = ({ question, answer }: Props) => {
  // State
  const [open, setOpened] = useState(false)

  // Refs
  const answerContainer = useRef<HTMLDivElement>(null)
  const answerText = useRef<HTMLDivElement>(null)

  // Helpers
  useEffect(() => {
    if (open) {
      answerContainer.current.style.height = `${answerText.current?.offsetHeight}px`
    } else {
      answerContainer.current.style.height = "0px"
    }
  }, [open])

  return (
    <div>
      <button
        onClick={() => setOpened(!open)}
        className="flex items-center mb-4 space-x-4 duration-200 ease-out focus:outline-none hover:text-blue-500 focus:text-blue-500"
      >
        <Text tag="p" className="font-medium text-left">
          {question}
        </Text>
        <div className="w-4 h-4">
          {open ? (
            <Minus className="w-4 h-4 text-blue-500" />
          ) : (
            <Plus className="w-4 h-4 text-blue-500" />
          )}
        </div>
      </button>
      <div
        className="overflow-hidden transition-all duration-200 ease-out bg-gray-900 rounded-lg"
        style={{ height: 0 }}
        ref={answerContainer}
      >
        <div className="p-4" ref={answerText}>
          <Text>{answer}</Text>
        </div>
      </div>
    </div>
  )
}

export default FAQ
