import React from "react"

//Component Imports
import { ExternalLink } from "../Button"

interface Props {
  Icon: any
  url: string

  children: string
}

const Social = ({ Icon, url }: Props) => (
  <ExternalLink
    href={url}
    className="flex w-20 mx-auto text-blue-500 transition duration-200 ease-out bg-gray-800 rounded-full md:w-24 xl:w-32 hover:text-white"
  >
    <Icon className="flex-1 p-6 md:p-6 xl:p-10" />
  </ExternalLink>
)

export default Social
