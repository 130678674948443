import React from "react"

//Component imports
import Text from "../Text"
import Image from "gatsby-image"

interface Props {
  img: string
  name: string
  position: string
  description: string
  flipped?: boolean
}

const TeamMember = ({ img, name, position, description }: Props) => (
  <div
    className="hover:shadow-lg group focus:shadow-lg focus:outline-none relative p-6 overflow-hidden bg-gray-900 rounded-lg shadow-md cursor-pointer"
    tabIndex={0}
  >
    <div className="space-y-4">
      <div>
        <Image
          fluid={img}
          alt={name}
          className="group-hover:scale-95 group-focus:scale-95 duration-300 ease-in-out transform rounded-lg"
        />
      </div>
      <div className={`flex flex-col items-center justify-center space-y-1`}>
        <Text tag="h3" styleTo="h2" className="font-semibold">
          {name}
        </Text>
        <p className="text-sm uppercase">{position}</p>
      </div>
    </div>
    <div className="group-hover:translate-y-0 group-focus:translate-y-0 group-hover:opacity-100 group-focus:opacity-100 absolute inset-0 flex items-center p-6 transition duration-300 ease-in-out transform translate-y-full bg-blue-600 rounded-lg opacity-0">
      <p className="text-base leading-loose">{description}</p>
    </div>
  </div>
)

export default TeamMember
