import React from "react"

import { graphql, useStaticQuery } from "gatsby"

// Component imports
import Layout from "../components/Layout"
import Section from "../components/Section"
import SEO from "../components/SEO"
import Hero from "../components/Hero"
import Text from "../components/Text"
import TeamMember from "../components/about/TeamMember"
import Social from "../components/about/Social"
import { Link } from "../components/Button"
import { Fade } from "react-awesome-reveal"

// Static assets
import { ReactComponent as Honeycomb } from "../images/honeycomb.svg"
import { ReactComponent as Instagram } from "../images/icons/instagram.svg"
import { ReactComponent as LinkedIn } from "../images/icons/linkedin.svg"
import { ReactComponent as Twitter } from "../images/icons/twitter.svg"
import { ReactComponent as YouTube } from "../images/icons/youtube.svg"
import { ReactComponent as Twitch } from "../images/icons/twitch.svg"
import FAQ from "../components/about/FAQ"

const About = () => {
  // Queries
  const data = useStaticQuery(graphql`
    {
      one: file(relativePath: { eq: "headshots/1 (new).jpg" }) {
        childImageSharp {
          fluid(quality: 95) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      two: file(relativePath: { eq: "headshots/2.png" }) {
        childImageSharp {
          fluid(quality: 95) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      three: file(relativePath: { eq: "headshots/3.png" }) {
        childImageSharp {
          fluid(quality: 95) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      four: file(relativePath: { eq: "headshots/4.png" }) {
        childImageSharp {
          fluid(quality: 95) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      five: file(relativePath: { eq: "headshots/5.png" }) {
        childImageSharp {
          fluid(quality: 95) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="About" />
      <Hero title="We Envision Your Imagination" />

      <Section>
        <Fade duration={500} triggerOnce delay={100} fraction={0.3}>
          <div className="lg:grid-cols-3 xl:gap-8 grid">
            <div className="lg:col-span-3 xl:col-span-2 col-span-1 mb-16">
              <div className="lg:mb-16 mb-12">
                <Text
                  tag="h1"
                  styleTo="h1"
                  className="lg:text-left text-center"
                >
                  We are...
                  <br className="lg:block hidden" /> Streamline{" "}
                  <span className="text-blue-500">Media</span>
                </Text>
              </div>

              <Text className="mb-8">
                Streamline Media is a premier and dynamic talent agency and a
                production and distribution company. Our goal is to bring the
                latest in Esports events to the digital screen by sourcing the
                very best in talent and production. We believe that by pooling
                the top talent and bringing unparalleled production and
                distribution to the fore, we get to showcase the top teams that
                are part of the Esport Arena.
              </Text>
              <Text className="mb-8">
                At Streamline Media, we invite you to become part of the lead
                production and distribution agency. Our streamlined processes
                ensure that the best quality production is achieved while
                ensuring newly recruited artists and influencers have an
                established platform to grow and make their mark in the
                industry.
              </Text>
              <Text className="mb-8">
                Our purpose is to ensure that we deliver on our promise of
                high-quality service and strategy for your next Esport event.
                Our solutions include the provision of outstanding event
                production and distribution. With an expert team to deliver the
                most impressive solutions, we can help you bring your next
                Esport event to the small and big screen.
              </Text>
              <Text className="mb-8">
                Our event production and distribution services are conducted by
                a highly expert team utilizing cutting edge technology and
                broadcasting solutions. With every project undertaking, we
                ensure that our audiences experience their favorite Esports
                events as never before!
              </Text>
              <Text className="mb-8">
                Streamline Media has partnered with Streamline gaming to bring
                you live events with crisp clarity and uninterrupted viewing
                pleasure.
              </Text>
            </div>
            <div className="lg:block hidden my-auto">
              <Honeycomb />
            </div>
          </div>
        </Fade>
      </Section>
      <div className="bg-gray-800">
        <Section>
          <div className="overflow-hidden">
            <Fade
              delay={100}
              duration={500}
              direction="up"
              fraction={0.5}
              triggerOnce
            >
              <Text
                tag="h1"
                styleTo="h1"
                className="lg:mb-16 mb-12 text-center"
              >
                Meet the Streamline <span className="text-blue-500">Team</span>
              </Text>
            </Fade>
          </div>
          <div className="md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 grid gap-8 mb-24">
            <Fade
              delay={100}
              duration={500}
              fraction={0.5}
              direction="up"
              cascade
              damping={0.2}
              triggerOnce
            >
              <TeamMember
                img={data.one.childImageSharp.fluid}
                name="Chan Zheng Man"
                position="Executive Director "
                description="As an esports enthusiast and a member of the gaming community, Chan understands the need for under-developed regions to be represented in the esports scene. Today, working with a global team, he strives to bring the best in esports from across the globe onto the international stage."
              />
              <TeamMember
                img={data.two.childImageSharp.fluid}
                name="Thomas Pond"
                flipped
                position="Casting Director"
                description="Thomas is a siege veteran who spends his free time researching the game and the way it’s played. He holds no bounds to regions working as a caster/analyst across APAC, NA, and EU. Outside of siege, Pond spent his college years as a D3 swimmer and finished his degree in Biomedical engineering. Pond now works full time as a biomedical imaging device field engineer, which is his dream career."
              />
              <TeamMember
                img={data.three.childImageSharp.fluid}
                name="Kelvin Koh"
                position="Head of Production, SEA"
                description="Kelvin, formerly a competitive player and esports coach with a combined 7 years of experience now joins Streamline to help in growing the esports scene in Asia. With a strong passion in esports, today he leads the production scene for Streamline in Asia."
              />
              <TeamMember
                img={data.four.childImageSharp.fluid}
                name="Marcus Nguyen"
                flipped
                position="Head of Casting, OC"
                description="Marcus is a Siege veteran, having played since 2016 and currently casts as well as plays support for Team Something. With 4 years experience on PS4 and 1 year on PC, he'll tell you about all the old days of siege, despite being younger than 20. Outside of Siege, Sawt is a student in the middle of his Immunology and International Relations studies."
              />
              <TeamMember
                img={data.five.childImageSharp.fluid}
                name="Kaustav"
                position="Head of Casting, SA"
                description={`Kaustav "Dovah" Kashyap Das has been in the Rainbow Six Scene in the Indian Sub Continent for over 4 years now. He believes in teamwork and co-ordination over aim and gunplay. Having had years of competitive experience as a Pro Rainbow Six player, playing for teams like Akatsuki( now KIRA Esports ), and  LYSE VALIANTS. He has a whole lot of expereince that he brings to the casting table.`}
              />
            </Fade>
          </div>

          <div className="md:flex-row flex flex-col justify-center mb-24">
            <Text className="md:pb-0 md:pr-8 pb-8 my-auto">
              Have a question for our team?
            </Text>
            <Link url="/contact">Get In Touch</Link>
          </div>
          <Fade delay={100} duration={500} fraction={0.25} triggerOnce cascade>
            <Text className="md:text-center xxl:w-10/12 mx-auto mb-8">
              Every successful business relies on a great team, and Streamline
              Studios is no different.
            </Text>
            <Text className="md:text-center xxl:w-10/12 mx-auto">
              At Streamline Media, our team is built on a strong collaboration
              to provide extraordinary service and exceptional teamwork. We
              boast of team members who are passionately dedicated to creating
              strong, long-term relationships with each client and candidate we
              work with. Every team-member is hard-working, knowledgeable,
              goal-oriented, passionate, and motivated about providing you an
              extraordinary experience.
            </Text>
          </Fade>
        </Section>
        <Section>
          <div className="overflow-hidden">
            <Fade
              delay={100}
              duration={500}
              direction="up"
              fraction={0.5}
              triggerOnce
            >
              <Text
                tag="h1"
                styleTo="h1"
                className="lg:mb-16 mb-12 text-center"
              >
                Learn <span className="text-blue-500">more</span> about us.
              </Text>
            </Fade>
          </div>

          <div className="grid gap-8">
            <Fade
              delay={100}
              duration={500}
              triggerOnce
              cascade
              direction="up"
              fraction={0.3}
            >
              <FAQ
                question="Discover a Leading Production and Distribution Team"
                answer="Streamline Media includes an experienced production and distribution team. We handle every aspect of your event with high-quality production techniques. 
Our team consists of skilled, qualified, and experienced technicians with advanced equipment and digital techniques to bring your sports event to life.
"
              />
              <FAQ
                question="Our Sponsorships"
                answer="Streamline Media will provide opportunities to companies looking to sponsor an event or promote media techniques through our talents. By partnering with the top companies, we can help you with artists, influencers, and much more.
"
              />
              <FAQ
                question="Our Mission"
                answer="We provide lead production services for a variety of Esports events. Streamline Media will make your venture a reality when you need to live broadcasting and a dedicated distribution company to maximize your exposure.
"
              />
              <FAQ
                question="Our Vision"
                answer="Streamline Media aims to provide small to large screen events with the highest quality production and distribution technology. By partnering with lead talent agencies, we bring the best artists to the fore. Our purpose is to become the fastest growing and most effective distributors and producers in the Esports industry.
"
              />
            </Fade>
          </div>
        </Section>
      </div>
      <Section>
        <Fade delay={100} duration={500} fraction={0.3} triggerOnce>
          <div className="lg:flex-row lg:items-center lg:space-x-12 flex flex-col">
            <div className="lg:mb-16 xxl:w-8/12 mb-12">
              <Text tag="h1" styleTo="h1">
                Connect with Streamline through our{" "}
                <span className="text-blue-500">social media</span> presence
              </Text>
            </div>
            <div className="md:flex md:justify-evenly xxl:w-full grid grid-cols-2 gap-6">
              <Social
                Icon={Instagram}
                url="https://www.instagram.com/streamline_group/"
              >
                Instagram
              </Social>
              <Social
                Icon={LinkedIn}
                url="https://www.linkedin.com/company/group-streamline/"
              >
                LinkedIn
              </Social>
              <Social Icon={Twitter} url="https://twitter.com/group_str">
                Twitter
              </Social>
              <Social
                Icon={YouTube}
                url="https://www.youtube.com/channel/UC3hYmQX6BtRHOwKXzrlSkZw"
              >
                YouTube
              </Social>
              <Social Icon={Twitch} url="https://twitch.tv/streamlinemedia">
                Twitch
              </Social>
            </div>
          </div>
        </Fade>
      </Section>
    </Layout>
  )
}

export default About
